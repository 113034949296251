<template>
	<div class="transaction-summary">
		<div class="participants" v-if="hasSiacoinInputs">
			<template v-for="({ self, siacoins }, address) in siacoinInputParticipants" :key="address">
				<div class="participant-icon">
					<identicon :value="address" />
				</div>
				<div class="participant-address"><input type="text" :value="address" readonly /></div>
				<div class="participant-tag">
					<span class="tag" v-if="self">Foundation</span>
				</div>
				<div>
					<div :class="{ 'amount-primary': true, 'amount-negative': siacoins.lt(0) }"> {{ formatSiacoinString(siacoins) }}</div>
					<div class="amount-secondary"> {{ formatCurrencyString(siacoins) }} <span class="exchange-rate">@ {{ formattedRate }}</span></div>
				</div>
			</template>
		</div>
		<div class="participants" v-if="hasSiacoinOutputs">
			<template v-for="({ self, siacoins }, address) in siacoinOutputParticipants" :key="address">
				<div class="participant-icon">
					<identicon :value="address" />
				</div>
				<div class="participant-address"><input type="text" :value="address" readonly /></div>
				<div class="participant-tag">
					<span class="tag" v-if="self">Foundation</span>
				</div>
				<div>
					<div :class="{ 'amount-primary': true, 'amount-negative': siacoins.lt(0) }"> {{ formatSiacoinString(siacoins) }}</div>
					<div class="amount-secondary">{{ formatCurrencyString(siacoins) }} <span class="exchange-rate">@ {{ formattedRate }}</span></div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { BigNumber } from 'bignumber.js';
import { formatSiacoinString, formatCurrencyString, formatExchangeRate } from '@/format';

import Identicon from '@/components/Identicon';

export default {
	components: {
		Identicon
	},
	props: {
		transaction: Object
	},
	computed: {
		...mapState(['currencyCode']),
		siacoinInputParticipants() {
			if (!this.transaction)
				return {};

			const participants = {};
			for (const part in this.transaction.participants) {
				const siacoins = new BigNumber(this.transaction.participants[part].siacoin_output).minus(this.transaction.participants[part].siacoin_input),
					siafunds = new BigNumber(this.transaction.participants[part].siafund_output).minus(this.transaction.participants[part].siafund_input);

				if (siacoins.gt(0))
					continue;

				participants[part] = {
					siacoins,
					siafunds,
					self: this.transaction.participants[part].self
				};
			}

			return participants;
		},
		siacoinOutputParticipants() {
			if (!this.transaction)
				return {};

			const participants = {};
			for (const part in this.transaction.participants) {
				const siacoins = new BigNumber(this.transaction.participants[part].siacoin_output).minus(this.transaction.participants[part].siacoin_input),
					siafunds = new BigNumber(this.transaction.participants[part].siafund_output).minus(this.transaction.participants[part].siafund_input);

				if (siacoins.lte(0))
					continue;

				participants[part] = {
					siacoins,
					siafunds,
					self: this.transaction.participants[part].self
				};
			}

			return participants;
		},
		hasSiacoinOutputs() {
			return Object.keys(this.siacoinOutputParticipants).length !== 0;
		},
		hasSiacoinInputs() {
			return Object.keys(this.siacoinInputParticipants).length !== 0;
		},
		formattedRate() {
			const rate = parseFloat(this.transaction?.exchange_rate?.rate || '0'),
				currency = this.transaction?.exchange_rate?.currency || 'usd';

			return formatExchangeRate(rate, currency, 'never');
		}
	},
	methods: {
		formatSiacoinString(val, sign = 'always') {
			return formatSiacoinString(val, sign);
		},
		formatCurrencyString(val, sign = 'always') {
			const rate = parseFloat(this.transaction?.exchange_rate?.rate || '0'),
				currency = this.transaction?.exchange_rate?.currency || 'usd';

			return formatCurrencyString(val, rate, currency);
		}
	}
};
</script>

<style lang="stylus" scoped>
.participants {
	display: grid;
	padding: 15px;
	margin-bottom: 10px;
	grid-template-columns: 40px minmax(0, 1fr) minmax(80px, auto) auto;
	grid-gap: 15px;
	align-items: center;
	background: #2b2b2b;
	border-radius: 16px;

	.title {
		grid-column: 1 / -1;
		color: rgba(255, 255, 255, 0.54);
	}
}

.participant-address {
	color: rgba(255, 255, 255, 0.54);

	input {
		display: block;
		width: 100%;
		background: none;
		outline: none;
		border: none;
		color: inherit;
		font-size: inherit;
		text-align: inherit;
		text-overflow: ellipsis;
	}
}

.amount-primary, .amount-secondary {
	text-align: right;
}

.amount-primary {
	font-size: 1.2rem;
	color: primary;

	&.amount-negative {
		color: negative;
	}
}
</style>