<template>
	<div class="transaction-raw">
		<div class="participants" v-if="hasSiacoinInputs">
			<div class="title">Siacoin Inputs</div>
			<template v-for="(inputs, address) in siacoinInputs" :key="address">
				<div class="participant-icon">
					<identicon :value="address" />
				</div>
				<div class="participant-address"><input type="text" :value="address" readonly /></div>
				<div class="participant-tag">
					<span class="tag" v-if="inputs[0].owned">Foundation</span>
				</div>
				<div class="sub-detail" v-for="input in inputs" :key="input.output_id">
					<div class="detail-id"><input type="text" :value="input.output_id" /></div>
					<div class="detail-value">
						<div class="amount-primary">{{ formatSiacoinString(input.value, 'never') }}</div>
						<div class="amount-secondary">{{ formatCurrencyString(input.value, 'never') }} <span class="exchange-rate">@ {{ formattedRate }}</span></div>
					</div>
				</div>
			</template>
		</div>
		<div class="participants" v-if="hasSiacoinOutputs">
			<div class="title">Siacoin Outputs</div>
			<template v-for="(outputs, address) in siacoinOutputs" :key="address">
				<div class="participant-icon">
					<identicon :value="address" />
				</div>
				<div class="participant-address"><input type="text" :value="address" readonly /></div>
				<div class="participant-tag">
					<span class="tag" v-if="outputs[0].owned">Foundation</span>
				</div>
				<div class="sub-detail" v-for="output in outputs" :key="output.output_id">
					<router-link v-if="output.spent_transaction_id" :to="{ name: 'transaction detail', params: { transactionID: output.spent_transaction_id }}" class="detail-id  output-spent"><input type="text" :value="output.output_id" /></router-link>
					<div class="detail-id" v-else><input type="text" :value="output.output_id" /></div>
					<div class="detail-tag">
						<span class="tag" v-if="output.spent_transaction_id">Spent</span>
					</div>
					<div class="detail-value">
						<div class="amount-primary">{{ formatSiacoinString(output.value, 'never') }}</div>
						<div class="amount-secondary">{{ formatCurrencyString(output.value, 'never') }} <span class="exchange-rate">@ {{ formattedRate }}</span></div>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { BigNumber } from 'bignumber.js';
import { formatSiacoinString, formatCurrencyString, formatExchangeRate } from '@/format';

import Identicon from '@/components/Identicon';

export default {
	components: {
		Identicon
	},
	props: {
		transaction: Object
	},
	computed: {
		...mapState(['currencyCode']),
		siacoinOutputs() {
			return (this.transaction?.siacoin_outputs || []).reduce((map, o) => {
				if (!map[o.unlock_hash])
					map[o.unlock_hash] = [];

				o.value = new BigNumber(o.value);
				map[o.unlock_hash].push(o);

				return map;
			}, {});
		},
		siacoinInputs() {
			return (this.transaction?.siacoin_inputs || []).reduce((map, i) => {
				if (!map[i.unlock_hash])
					map[i.unlock_hash] = [];

				i.value = new BigNumber(i.value);
				map[i.unlock_hash].push(i);

				return map;
			}, {});
		},
		hasSiacoinOutputs() {
			return Object.keys(this.siacoinOutputs).length !== 0;
		},
		hasSiacoinInputs() {
			return Object.keys(this.siacoinInputs).length !== 0;
		},
		formattedRate() {
			const rate = parseFloat(this.transaction?.exchange_rate?.rate || '0'),
				currency = this.transaction?.exchange_rate?.currency || 'usd';

			return formatExchangeRate(rate, currency, 'never');
		}
	},
	methods: {
		formatSiacoinString(val, sign = 'always') {
			return formatSiacoinString(val, sign);
		},
		formatCurrencyString(val, sign = 'always') {
			const rate = parseFloat(this.transaction?.exchange_rate?.rate || '0'),
				currency = this.transaction?.exchange_rate?.currency || 'usd';

			return formatCurrencyString(val, rate, currency);
		}
	}
};
</script>

<style lang="stylus" scoped>
.participants {
	display: grid;
	padding: 15px;
	margin-bottom: 10px;
	grid-template-columns: 40px minmax(0, 1fr) auto;
	grid-gap: 15px;
	align-items: center;
	background: #2b2b2b;
	border-radius: 16px;

	.title {
		grid-column: 1 / -1;
		color: rgba(255, 255, 255, 0.54);
	}
}

.participant-address, .detail-id {
	color: rgba(255, 255, 255, 0.54);

	input {
		display: block;
		width: 100%;
		background: none;
		outline: none;
		border: none;
		color: inherit;
		font-size: inherit;
		text-align: inherit;
		text-overflow: ellipsis;
	}
}

.output-spent {
	cursor: pointer !important;
	text-decoration: none;
	&:hover {
		color: rgba(255, 255, 255, 0.84);
	}

	input {
		pointer-events: none;
	}
}

.sub-detail {
	display: grid;
	padding: 15px;
	grid-template-columns: minmax(0, 1fr) repeat(2, auto);
	grid-gap: 15px;
	align-items: center;
	grid-column: 1 / -1;

	span.tag {
		background: #c6ca93;
	}
}

.amount-primary, .amount-secondary {
	text-align: right;
}

.amount-primary {
	font-size: 1.2rem;
	color: primary;

	&.amount-negative {
		color: negative;
	}
}
</style>